/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'exclamation-diamond': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 010-2.098zm1.4.7a.495.495 0 00-.7 0L1.134 7.65a.495.495 0 000 .7l6.516 6.516a.495.495 0 00.7 0l6.516-6.516a.495.495 0 000-.7L8.35 1.134z"/><path pid="1" d="M7.002 11a1 1 0 112 0 1 1 0 01-2 0M7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0z"/>',
    },
});
